<template>
  <v-navigation-drawer
    v-model="open"
    class="style-drawer"
    :class="[open ? 'style-drawer--open' : '']"
    fixed
    temporary
    right
    hide-overlay
    :width="280"
  >
    <div class="style-drawer__scroll">
      <div class="style-drawer__content d-flex flex-column justify-start pb-6">
        <h4 class="font-weight-light">{{ $t('heading.styleManager.title') }}</h4>
        <div class="style-drawer__items-container">
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6">{{ $t('heading.styleManager.logo.title') }}</h5>
            <style-manager-change-logo
              :fileData="customLogo"
              @update:file="changeSelectedFile"
              @update:fileData="changeFileData"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6">{{ $t('heading.styleManager.favicon.title') }}</h5>
            <style-manager-change-favicon
              :faviconData="customFavicon"
              @update:file="changeSelectedFavicon"
              @update:faviconData="changeFaviconData"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6">{{ $t('heading.styleManager.colorScheme.title') }}</h5>
            <style-manager-color-group-picker
              :selectedColors="selectedColors"
              @update:selectedColors="changeSelectedColorsTheme"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6">{{ $t('heading.styleManager.primaryColor.title') }}</h5>
            <style-manager-group-editor
              :selectedColors="selectedColors"
              @update:selectedColors="changeSelectedColorsTheme"
            />
          </div>
          <div
            class="style-drawer__item mb-6"
            v-if="Object.keys(this.selectedStyle).length"
          >
            <h5 class="p-2 mb-4 mt-6">{{ $t('heading.styleManager.additionalSettings.title') }}</h5>
            <div class="d-flex flex-column">
              <div class="d-flex align-center">
                <v-switch
                  @change="changeShadowStyleSelection"
                  class="my-0 py-0 mr-4"
                  hide-details
                  v-model="this.selectedStyle.disableShadows"
                />
                <p class="p-3 mb-0">{{ $t('heading.styleManager.additionalSettings.disableShadows.title') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-auto">
          <v-btn
            color="primary"
            large
            elevation="0"
            class="mb-2"
            @click="saveColorsTheme"
            >{{ $t('button.saveChanges') }}</v-btn
          >

          <v-btn
            color="primary lighten-2 primary--text text--darken-1"
            large
            elevation="0"
            @click="restoreDefault"
            >{{ $t('button.restoreDefault') }}</v-btn
          >
        </div>
      </div>
    </div>

    <div
      class="style-drawer__activator"
      v-ripple="'primary'"
      @click="open = !open"
    >
      <div class="style-drawer__activator-content">
        <div class="style-drawer__activator-icon">
          <v-icon size="24" color="primary">$sliders</v-icon>
        </div>
        <h6 class="p-5 style-drawer__activator-title">{{ $t('button.customize') }}</h6>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import StyleManagerColorGroupPicker from "./StyleManagerColorGroupPicker.vue";
import StyleManagerGroupEditor from "./StyleManagerGroupEditor.vue";
import StyleManagerChangeLogo from "./StyleManagerChangeLogo.vue";
import Api from "@/apis/Api";
import StyleManagerChangeFavicon from "./StyleManagerChangeFavicon.vue";

export default {
  components: {
    StyleManagerColorGroupPicker,
    StyleManagerGroupEditor,
    StyleManagerChangeLogo,
    StyleManagerChangeFavicon,
  },
  data: function () {
    return {
      fileData: this.$store.state.ThemeModule.logo,
      faviconData: this.$store.state.ThemeModule.favicon,
      selectedColors: this.$store.state.ThemeModule.colors.primary,
      selectedStyle: this.$store.state.ThemeModule.style,
      open: false,
    };
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    customFavicon: function () {
      if (!this.$store.state.ThemeModule.favicon) {
        return false;
      }

      return this.$store.state.ThemeModule.favicon;
    },
  },
  watch: {
    "$route.name": function (name) {
      if (process.env.VUE_APP_DEMO_MODE && name == "Login") {
        this.open = true;
      }
    },
    "$route.query": function (query) {
      if (query.branding) {
        this.open = true;
      }
    },
    open: function () {
      this.selectedColors = this.$store.state.ThemeModule.colors.primary;
    },
  },
  methods: {
    changeSelectedFile: function (data) {
      this.file = data;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeSelectedFavicon: function (data) {
      this.favicon = data;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeSelectedColorsTheme: function (theme) {
      this.selectedColors = theme;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeColorsTheme: function () {
      this.$store.commit("changeColorsTheme", {
        colors: this.selectedColors,
        style: this.selectedStyle,
        logo: this.fileData,
        favicon: this.faviconData,
      });
    },
    changeShadowStyleSelection: function () {
      this.selectedStyle.disableShadows = !this.selectedStyle.disableShadows;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeFileData: function (data) {
      this.fileData = data;
      // this.selectedStyle.logo = this.fileData;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeFaviconData: function (data) {
      this.faviconData = data;
      // this.selectedStyle.logo = this.fileData;

      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    restoreDefault: function () {
      this.selectedColors = {
        base: "#07C07E",
        lighten1: "#1FD895",
        lighten2: "#CDF2E5",
        lighten4: "#E6F9F2",
        darken1: "#0AAA71",
      };
      this.selectedStyle = {
        disableShadows: false,
      };
      this.fileData = false;
      this.faviconData = false;

      this.saveColorsTheme();
    },
    saveColorsTheme() {
      this.changeColorsTheme();

      localStorage.setItem(
        "theme",
        JSON.stringify({
          colors: this.selectedColors,
          style: this.selectedStyle,
          logo: this.fileData,
          favicon: this.faviconData,
        })
      );
      if (this.$store.state.home.admin) {
        Api.put(`/settings/userapp-branding`, {
          colors: this.selectedColors,
          style: this.selectedStyle,
          logo: this.fileData,
          favicon: this.faviconData,
        });
      }
      this.$store.dispatch("addAlert", {
        success: true,
        successMessage: this.$t('notification.style.update.success'),
      });
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.selectedStyle = this.$store.state.ThemeModule.style;
    });
  },
  created() {
    this.$router.onReady(() => {
      if (this.$route.query.branding) {
        setTimeout(() => {
          this.open = true;
        }, 1000);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.style-drawer {
  visibility: visible;
  overflow: visible !important;
  z-index: 1010;
  box-shadow: none !important;
  background: #fff;
  transition-property: transform, visibility, width, margin;

  &:not(.style-drawer--open):hover {
    margin-right: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 100%;
    z-index: -10;
    left: 0;
    top: 0;
    box-shadow: 0px 12px 32px 6px rgb(0 0 0 / 6%),
      0px 16px 72px 8px rgb(0 0 0 / 8%);
    opacity: 0;
    transition: opacity 0.24s ease;
  }

  &--open,
  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &__content {
    padding: 32px;

    min-height: 100%;
  }

  &__items-container {
    min-height: 100%;
  }

  &.v-navigation-drawer::v-deep {
    .v-navigation-drawer__content {
      overflow: visible !important;
      // overflow-y: auto !important;
      background: #fff;

      .style-drawer__scroll {
        overflow-y: auto !important;
        max-height: 100%;

        &::-webkit-scrollbar {
          width: 14px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          background-color: transparent;
          max-width: 8px;
          border: 3px solid transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--v-gray-lighten2);
          border-radius: 8px;
          border: 4px solid transparent;
          background-clip: padding-box;
        }
      }
    }
  }

  .v-navigation-drawer__border {
    display: none;
  }

  &__activator {
    background: #fff;
    cursor: pointer;
    width: 98px;
    height: 98px;
    position: absolute;
    top: 208px;
    left: -96px;
    border-radius: 8px 0px 0px 8px;
    padding: 8px 20px 18px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    box-shadow: 0px 12px 32px 6px rgb(0 0 0 / 6%),
      0px 16px 72px 8px rgb(0 0 0 / 8%);

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      min-height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      color: map-get($text, headings);
      font-weight: $font-weight-semibold;
      line-height: 14px;
    }
  }
}
.v-application--is-rtl{
    .style-drawer{
        &__activator{
            left: unset;
            right: -96px;
            border-radius: 0px 8px 8px 0px;
        }
    }
    .v-navigation-drawer{
        &--right{
            left:0;
            right: unset;
            
        }
        &--close{
            transform: translateX(-100%)!important;
        }
        &--open{
            transform: translateX(0%) !important;
        }
    }
}
</style>