<template>
  <div class="demo-footer mt-10 py-4">
    <div class="container px-0">
      <div class="demo-footer__row">
        <div class="demo-footer__copyright">
          © 2021 - 2024 PanelAlpha. All rights reserved.
        </div>
        <div class="demo-footer__socials">
          <v-form class="demo-footer__subscribe mr-2" ref="form">
            <v-text-field
              outlined
              dense
              hide-details=""
              placeholder="example@mail.com"
              v-model="subscribeInput"
              :rules="emailRules"
            >
              <template v-slot:prepend-inner>
                <v-icon class="" size="24">$mail</v-icon>
              </template>
              <template v-slot:append>
                <v-tooltip
                  open-delay="150"
                  content-class="dropdown-top"
                  offset-overflow
                  nudge-top="8px"
                  top
                  transition="custom-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" class="" size="16"
                      >$help</v-icon
                    >
                  </template>
                  Subscribe to PanelAlpha's newsletter to sneak behind
                  the scenes and access exclusive details.
                </v-tooltip>
              </template>
            </v-text-field>
            <v-btn
              @click.native="subscribe"
              color="primary"
              elevation="0"
              class="mx-4"
            >
              Subscribe
            </v-btn>
          </v-form>
          <div class="demo-footer__social-buttons">
            <v-btn icon text href="https://twitter.com/PanelAlpha">
              <v-icon>$twitter</v-icon>
            </v-btn>
            <v-btn icon text href="https://www.facebook.com/PanelAlpha">
              <v-icon>$fb</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonp from "jsonp";
import FormRulesMixin from "../../mixins/FormRulesMixin";

export default {
  mixins: [FormRulesMixin],
  data: function () {
    return {
      subscribeInput: "",
    };
  },
  methods: {
    subscribe: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      jsonp(
        `https://panelalpha.us5.list-manage.com/subscribe/post-json?u=0e4b32254c7887ae2458fb3aa&amp;id=8888e0c1c1&EMAIL=${this.subscribeInput}&b_0e4b32254c7887ae2458fb3aa_8888e0c1c1=''`,
        {
          param: "c",
        },
        (err, data) => {
          if (err) {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: err,
            });
          } else {
            if (data.result === "error") {
              this.$store.dispatch("addAlert", {
                success: false,
                errorMessage: data.msg,
              });
            } else {
              this.$store.dispatch("addAlert", {
                success: true,
                successMessage: data.msg,
              });
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-footer {
  border-top: 1px solid map-get($gray, lighten3);
  margin-top: auto;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__socials {
    display: flex;
  }
  &__subscribe {
    display: flex;
    align-items: center;
  }
  &__social-buttons {
    .v-btn {
      color: map-get($text, headings);
      &::before {
        background: map-get($primary, base);
      }
      &:hover {
        color: map-get($primary, base);
        .v-icon {
          color: map-get($primary, darken1);
        }
      }
      + .v-btn {
        margin-left: 8px;
      }
    }
  }
  .v-input {
    margin-bottom: 0px !important;
  }
  @media (max-width: map-get($grid-breakpoints, lg)) {
    &__row {
      flex-direction: column-reverse;
    }
    &__copyright {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    &__socials {
      flex-direction: column;
      align-items: center;
    }
    &__social-buttons {
      margin-top: 16px;
    }
  }
}
</style>